
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import TableV1 from "@/app/ui/components/table/index.vue";
import { TransportController } from "@/app/ui/controllers/TransportController";
import debounce from "lodash/debounce";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { ROUTE_TRANSPORT } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    EmptyState,
    TableV1
  },
  computed: {
    getCurrentRoute() {
      return this.$route.meta.title;
    },
    isShowTable() {
      return this.dataTransport.length > 0 && !this.isLoading && !this.isError;
    },
    isDetailAble() {
      return checkRolePermission(ROUTE_TRANSPORT.DETAIL);
    }
  }
})
export default class TransportManagement extends Vue {
  mounted() {
    this.getListTransport();
    TransportController.setSearch("");
    TransportController.setStatus("");
    TransportController.setType("");
  }

  // API Controllers
  getListTransport() {
    this.firstPage();
    return TransportController.fetchTransportVendorList();
  }
  get dataTransport() {
    return TransportController.transportData.data;
  }
  get isFirstRequest() {
    return TransportController.firstRequest;
  }

  //Filter Search
  search = "";
  onSearch(value: string) {
    TransportController.setSearch(value);
    TransportController.searchAction(value);
  }

  get searchValue() {
    return TransportController.search;
  }
  clearSearch() {
    TransportController.clear();
    this.search = "";
    this.firstPage();
  }

  //filter type
  typeData = [
    { name: "Semua Tipe", value: "" },
    { name: "Train", value: "train" },
    { name: "Truck", value: "truck" },
    { name: "Cargo", value: "cargo" }
  ];
  typeSelect = false;
  typeName = "";
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  get typeValue() {
    return TransportController.type;
  }
  onSelectType(name: string, value: string) {
    TransportController.selectTypeAction({
      name: name,
      value: value
    });
    this.typeName = name;
    this.onCloseTypeSelect();
    this.firstPage();
  }

  // filter status
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  get statusValue() {
    return TransportController.status;
  }
  onSelectStatus(name: string, value: string) {
    TransportController.selectStatusAction({
      name: name,
      value: value
    });
    this.statusName = name;
    this.onCloseStatusSelect();
    this.firstPage();
  }

  // Pagination Table
  get page() {
    return TransportController.page;
  }
  get limit() {
    return TransportController.limit;
  }
  nextPage() {
    TransportController.setNextPage();
  }
  firstPage() {
    return TransportController.setFirstPage();
  }
  prevPage() {
    TransportController.setPrevPage();
  }
  toPage = debounce(value => {
    TransportController.setPageAction(value);
  }, 500);

  // Table
  columns = [
    {
      name: "ID Transportasi",
      key: "id",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200",
      render: (item: any) => {
        return `<span class='text-center text-red-lp-200'>${item.id}</span>`;
      }
    },
    {
      name: "Kode Transportasi",
      key: "transportCode",
      styleHead: "w-1/20 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.transportCode}</div>`;
      }
    },
    {
      name: "Tipe Transportasi",
      key: "transport_type",
      styleHead: "w-1/20 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='text-black-lp-300 capitalize mr-2 flex flex-row'><div class='rounded px-2 py-0 bg-gray-lp-200'>${item.transportType}</div></div>`;
      }
    },
    {
      name: "Nama Vendor",
      key: "venodrName",
      styleHead: "w-1/20 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.vendorName}</div>`;
      }
    },
    {
      name: "Kode Vendor",
      key: "vendorCode",
      styleHead: "w-1/20 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.vendorCode}</div>`;
      }
    },
    {
      name: "Status Transportasi",
      key: "status",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<div class="flex">
        <div class="rounded-full px-2 py-0 ${
          item.status.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.status.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
        ><span class="capitalize">${item.status.toLowerCase()}</span></div></div>`;
      }
    }
  ];
  onClickRow(item: any) {
    this.$router.push(`/network/transport-management/${item.id}`);
  }

  //Loading
  get isLoading() {
    return TransportController.isLoading;
  }

  //Error
  get isError() {
    return TransportController.isError;
  }

  //Error Cause
  get errorCause() {
    return TransportController.isErrorCause;
  }
}
