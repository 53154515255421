import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "absolute w-full bottom-0" }
const _hoisted_3 = { class: "border-t border-gray-lp-200 border-b overflow-y-auto h-screen pb-24" }
const _hoisted_4 = { class: "absolute w-full bottom-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_TableLazyLoad = _resolveComponent("TableLazyLoad")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV1 = _resolveComponent("TableV1")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      currentRoutes: _ctx.getCurrentRoute,
      isSearchable: true,
      isSelectType: true,
      isSelectStatus: true,
      placeholder: "Cari tipe transportasi atau nama vendor",
      searchValue: _ctx.searchValue,
      searchAction: _ctx.onSearch,
      clearSearch: _ctx.clearSearch,
      typeData: _ctx.typeData,
      statusData: _ctx.statusData,
      onOpenTypeSelect: _ctx.onOpenTypeSelect,
      onCloseTypeSelect: _ctx.onCloseTypeSelect,
      onSelectType: _ctx.onSelectType,
      valueType: _ctx.typeName,
      typeSelect: _ctx.typeSelect,
      onOpenStatusSelect: _ctx.onOpenStatusSelect,
      onCloseStatusSelect: _ctx.onCloseStatusSelect,
      onSelectStatus: _ctx.onSelectStatus,
      valueStatus: _ctx.statusName,
      statusSelect: _ctx.statusSelect
    }, null, 8, ["currentRoutes", "searchValue", "searchAction", "clearSearch", "typeData", "statusData", "onOpenTypeSelect", "onCloseTypeSelect", "onSelectType", "valueType", "typeSelect", "onOpenStatusSelect", "onCloseStatusSelect", "onSelectStatus", "valueStatus", "statusSelect"]),
    _withDirectives(_createVNode(_component_TableLazyLoad, { columns: _ctx.columns }, null, 8, ["columns"]), [
      [_vShow, _ctx.isLoading]
    ]),
    _withDirectives(_createVNode(_component_ErrorMessage, { errorType: "notfound" }, null, 512), [
      [_vShow, 
        _ctx.dataTransport.length === 0 &&
          !_ctx.isLoading &&
          _ctx.page === 1 &&
          !_ctx.isFirstRequest
      ]
    ]),
    _withDirectives(_createVNode(_component_ErrorMessage, {
      errorType: _ctx.errorCause,
      onTryAgain: _ctx.getListTransport
    }, null, 8, ["errorType", "onTryAgain"]), [
      [_vShow, _ctx.isError && !_ctx.isLoading]
    ]),
    _withDirectives(_createVNode("div", null, [
      _createVNode(_component_ErrorMessage, { errorType: "lastPage" }),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_Pagination, {
          onNext: _ctx.nextPage,
          onPrevious: _ctx.prevPage,
          page: _ctx.page,
          toPage: _ctx.toPage
        }, null, 8, ["onNext", "onPrevious", "page", "toPage"])
      ])
    ], 512), [
      [_vShow, _ctx.dataTransport.length === 0 && !_ctx.isLoading && _ctx.page !== 1]
    ]),
    _withDirectives(_createVNode(_component_EmptyState, { data: "data Transport" }, null, 512), [
      [_vShow, 
        _ctx.dataTransport.length === 0 &&
          !_ctx.isError &&
          !_ctx.isLoading &&
          _ctx.page === 1 &&
          _ctx.searchValue === '' &&
          _ctx.statusName === '' &&
          _ctx.typeName === '' &&
          _ctx.isFirstRequest
      ]
    ]),
    (_ctx.isShowTable)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_TableV1, {
              columns: _ctx.columns,
              data: _ctx.dataTransport,
              class: "my-2",
              onClick: _ctx.onClickRow,
              isDetailAble: _ctx.isDetailAble
            }, null, 8, ["columns", "data", "onClick", "isDetailAble"])
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_Pagination, {
              onNext: _ctx.nextPage,
              onPrevious: _ctx.prevPage,
              page: _ctx.page,
              isButtonNext: _ctx.dataTransport.length >= _ctx.limit,
              totalData: _ctx.dataTransport.length,
              toPage: _ctx.toPage
            }, null, 8, ["onNext", "onPrevious", "page", "isButtonNext", "totalData", "toPage"])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}