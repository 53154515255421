
import { Options, Vue } from "vue-class-component";
import { dateToDateString, dateToTime } from "@/app/infrastructures/misc/Utils";
import Input from "@/app/ui/components/input/index.vue";

import { TransportController } from "@/app/ui/controllers/TransportController";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      TransportController.setOpenModalSuccess(false);
      next();
    }
  },
  computed: {
    createdAt() {
      return `${dateToDateString(
        new Date(this.dataDetail.createdAt)
      )}, ${dateToTime(new Date(this.dataDetail.createdAt))} WIB`;
    },
    updatedAt() {
      return `${dateToDateString(
        new Date(this.dataDetail.updatedAt)
      )}, ${dateToTime(new Date(this.dataDetail.updatedAt))} WIB`;
    }
  }
})
export default class Edit extends Vue {
  mounted() {
    this.fetchTransportData();
  }

  fetchTransportData() {
    this.getDetailTransportData(this.$route.params.id);
  }

  //Loading
  get isLoading() {
    return TransportController.isLoadingDetail;
  }

  //Error
  get isError() {
    return TransportController.isError;
  }

  //is Eligible to Activate
  get isErrorVendorStatus() {
    return TransportController.isErrorVendorStatus;
  }

  //Error Edit
  get isErrorEdit() {
    return TransportController.isErrorEdit;
  }

  handleErrorClose() {
    TransportController.setErrorEdit(false);
  }

  //Error Cause
  get errorCause() {
    return TransportController.isErrorCause;
  }

  getDetailTransportData(id: any) {
    TransportController.getTransportDetail({ id });
  }

  checkStatusVendor() {
    TransportController.getVendorDetail({ id: this.dataDetail.vendorId });
  }

  get dataDetail() {
    return TransportController.transportDetail;
  }

  _onEdit() {
    this.onCloseSave();
    this.handleErrorClose();
    TransportController.editTransport({
      id: this.dataDetail.id,
      status: this.dataDetail.status
    });
  }

  goBack() {
    this.$router.push(`/network/transport-management/${this.dataDetail.id}`);
  }

  isDisabled() {
    return true;
  }

  // Toggle Status
  openStatus = false;
  onOpenStatus() {
    if (this.isStatus) {
      this.openStatus = true;
    } else {
      this.checkStatusVendor();
    }
  }
  onCloseStatus() {
    this.openStatus = false;
  }
  onCloseActivateFailed() {
    TransportController.setErrorVendorStatus(false);
  }
  get isStatus() {
    return this.dataDetail.status === "active";
  }
  changeStatus() {
    if (this.isStatus) {
      this.onCloseStatus();
      this.dataDetail.status = "inactive";
    }
  }

  // Modal Success
  get openSuccess() {
    return TransportController.openModalSuccess;
  }
  onCloseSuccess() {
    this.$router.push(`/network/transport-management/${this.$route.params.id}`);
  }

  // Save data
  openSave() {
    return TransportController.openModal;
  }
  onOpenSave() {
    TransportController.setOpenModal(true);
  }
  onCloseSave() {
    TransportController.setOpenModal(false);
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }
}
