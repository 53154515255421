/* eslint-disable @typescript-eslint/camelcase */
import { EditTransportRequestInterface } from "../contracts/TransportRequest";

export class EditTransportApiRequest implements EditTransportRequestInterface {
  private transport_id: number;
  private transport_status: string;

  constructor(transport_id: number, transport_status: string) {
    this.transport_id = transport_id;
    this.transport_status = transport_status;
  }

  public toJSON(): string {
    return JSON.stringify({
      id: this.transport_id,
      status: this.transport_status
    });
  }
}
