
import { Options, Vue } from "vue-class-component";
import {
  checkRolePermission,
  dateToDateString,
  dateToTime
} from "@/app/infrastructures/misc/Utils";

import { TransportController } from "@/app/ui/controllers/TransportController";
import { ROUTE_TRANSPORT } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {},
  computed: {
    createdAt() {
      return `${dateToDateString(
        new Date(this.dataDetail.createdAt)
      )}, ${dateToTime(new Date(this.dataDetail.createdAt))} WIB`;
    },
    updatedAt() {
      return `${dateToDateString(
        new Date(this.dataDetail.updatedAt)
      )}, ${dateToTime(new Date(this.dataDetail.updatedAt))} WIB`;
    },
    isEditAble() {
      return checkRolePermission(ROUTE_TRANSPORT.EDIT);
    }
  }
})
export default class Detail extends Vue {
  mounted() {
    this.fetchTransportData();
  }

  fetchTransportData() {
    this.getDetailTransportData(this.$route.params.id);
  }

  //Loading
  get isLoading() {
    return TransportController.isLoadingDetail;
  }

  //Error
  get isError() {
    return TransportController.isError;
  }

  //Error Cause
  get errorCause() {
    return TransportController.isErrorCause;
  }

  getDetailTransportData(id: any) {
    return TransportController.getTransportDetail({ id });
  }

  get dataDetail() {
    return TransportController.transportDetail;
  }

  goBack() {
    this.$router.push("/network/transport-management/");
  }

  goEdit(id: string) {
    this.$router.push(`/network/transport-management/${id}/edit`);
  }
}
