/* eslint-disable @typescript-eslint/camelcase */
import {
  TransportEntities,
  TransportData,
  VendorData
} from "@/domain/entities/Transport";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { TransportPresenter } from "../presenters/TransportPresenter";
import { EditTransportApiRequest } from "@/data/payload/api/TransportApiRequest";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface TransportState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  isLoadingVendor: boolean;
  transportData: TransportEntities;
  transportDetail: TransportData;
  vendorStatus: VendorData;
  isErrorVendorStatus: boolean;
  isError: boolean;
  isErrorEdit: boolean;
  isErrorCause: string;
  openModal: boolean;
  openModalSuccess: boolean;
}
@Module({ namespaced: true, dynamic: true, store, name: "transport" })
class TransportStore extends VuexModule implements TransportState {
  public isLoading = false;
  public isLoadingDetail = false;
  public isLoadingVendor = false;
  public transportData = new TransportEntities(new Pagination(1, 10), []);
  public transportDetail = new TransportData(
    0,
    "",
    "",
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  );
  public vendorStatus = new VendorData("");
  public search = "";
  public type = "";
  public typeName = "";
  public status = "";
  public statusName = "";
  public page = 1;
  public limit = 10;
  public isError = false;
  public isErrorEdit = false;
  public isErrorVendorStatus = false;
  public isErrorCause = "";
  public openModal = false;
  public openModalSuccess = false;
  public firstRequest = false;

  @Action
  public getTransportList(params: {
    search: string;
    type: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(TransportPresenter);
    presenter
      .getTransportList(
        params.search,
        params.type,
        params.status,
        params.page,
        params.limit
      )
      .then((res: TransportEntities) => {
        this.setTransportList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getTransportDetail(params: { id: any }) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(TransportPresenter);
    presenter
      .getTransportDetail(params.id)
      .then((res: TransportData) => {
        this.setTransportDetail(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public editTransport(params: { id: number; status: string }) {
    MainAppController.showLoading();
    const presenter = container.resolve(TransportPresenter);
    presenter
      .editTransport(new EditTransportApiRequest(params.id, params.status))
      .then(() => {
        this.setErrorEdit(false);
        this.setErrorCause("");
        this.setOpenModalSuccess(true);
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Persetujuan Gagal !", () => {
            this.editTransport(params);
          })
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public getVendorDetail(params: { id: number }) {
    const presenter = container.resolve(TransportPresenter);
    presenter
      .getVendorDetail(params.id)
      .then((res: VendorData) => {
        this.setVendorStatus(res);
        if (res.status.toLowerCase() === "inactive") {
          this.setErrorVendorStatus(true);
        } else {
          this.setTransportDetailStatus("active");
        }
      })
      .catch(() => {
        this.setErrorVendorStatus(true);
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Mutation
  public setFirstRequest(val: boolean) {
    this.firstRequest = val;
  }

  @Action
  public initRequest() {
    this.setFirstRequest(true);
    this.getTransportList({
      search: "",
      type: "",
      status: "",
      page: 1,
      limit: 10
    });
  }

  @Action
  public fetchTransportVendorList() {
    this.setFirstRequest(false);
    this.getTransportList({
      search: "",
      type: "",
      status: "",
      page: 1,
      limit: 10
    });
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this.getTransportList({
      search: this.search,
      type: this.type,
      status: this.status,
      page: this.page,
      limit: 10
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this.getTransportList({
        search: this.search,
        type: this.type,
        status: this.status,
        page: this.page,
        limit: 10
      });
    }
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this.getTransportList({
      search: this.search,
      type: this.type,
      status: this.status,
      page: value,
      limit: 10
    });
  }

  @Action
  public selectStatusAction(params: { name: string; value: string }) {
    this.setStatus(params.value);
    this.setStatusName(params.name);
    this.getTransportList({
      search: this.search,
      type: this.type,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Action
  public selectTypeAction(params: { name: string; value: string }) {
    this.setType(params.value);
    this.setTypeName(params.name);
    this.getTransportList({
      search: this.search,
      type: this.type,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    this.getTransportList({
      search: value,
      type: this.type,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Action
  public clear() {
    this.searchAction("");
    this.getTransportList({
      search: "",
      type: this.type,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  private setLoadingVendor(isLoading: boolean) {
    this.isLoadingVendor = isLoading;
  }

  @Mutation
  private setTransportList(data: TransportEntities) {
    this.transportData = data;
  }

  @Mutation
  private setTransportDetail(data: TransportData) {
    this.transportDetail = data;
  }

  @Mutation
  public setTransportDetailStatus(value: string) {
    this.transportDetail.status = value;
  }

  @Mutation
  private setVendorStatus(data: VendorData) {
    this.vendorStatus = data;
  }

  @Mutation
  private async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  private async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  private async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  private async setFirst() {
    this.page = 1;
  }

  @Mutation
  public async setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public async setType(value: string) {
    this.type = value;
  }

  @Mutation
  public async setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  private async setTypeName(value: string) {
    this.typeName = value;
  }

  @Mutation
  private async setStatusName(value: string) {
    this.statusName = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  public setErrorVendorStatus(boolean: boolean) {
    this.isErrorVendorStatus = boolean;
  }

  @Mutation
  private setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }
}

export const TransportController = getModule(TransportStore);
